/* Mison text */

@font-face {
	font-family: 'MisonFamily';
	src: url('../fonts/mison/mison_text_regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'MisonFamily';
	src: url('../fonts/mison/mison_text_medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'MisonFamily';
	src: url('../fonts/mison/mison_text_medium.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'MisonFamily';
	src: url('../fonts/mison/mison_text_bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

:root {
	--primary: #FCE000;
	--primary-text-color: #000;
	--text-color: #000;

	--bg-color: #fff;
	--grey-color: #eee;
	--footer-bg-color: #fff;
	--header-card-bg-color: hsla(240, 7%, 97%, .9);

	--header-bg-color: #fff;
	--header-text-color: #555b6d;
	--sidebar-bg-color: #fff;
	--sidebar-text-color: #7b8190;
	--sidebar-item-active-text-color: #383c40;

	--card-bg-color: #F5F4F2;
	--card-text-color: #000;
	--btn-bg-color: #fff;
	--btn-bg-color-active: #dddddd;
}

body.dark {
	--bg-color: #191918;
	--grey-color: #505050;
	--footer-bg-color: #302F2D;

	--text-color: #fff;
	--header-card-bg-color: #2F2F2F;
	--card-bg-color: #302F2D;
	--card-text-color: #E0DEDA;
	--btn-bg-color: #42413E;
	--btn-bg-color-active: rgba(245, 244, 242, 0.2);
	--active-color: rgba(245, 244, 242, 0.2);

	background-color: var(--bg-color);
}

body {
	margin: 0;
	height: 100%;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-family: 'MisonFamily';
	color: var(--text-color, #000);
	background-color: var(--bg-color);
}

.fz-18 {
	font-size: 18px !important;
}

.fz-20 {
	font-size: 20px !important;
}

.mr-10 {
	margin-right: 10px;
}

.mb-30 {
	margin-bottom: 30px;
}

.tg-header {
	position: sticky;
	top: 0;
	background-color: var(--bg-color, #fff);
	box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
	z-index: 2;
}

.tg-header .title {
	font-size: 24px;
	font-weight: bold;
	text-align: center;
	padding-top: 10px;
}

.tg-header-flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 16px 0;
}

::-webkit-scrollbar {
	display: none;
}

.menu {
	height: 60px;
	display: flex;
	align-items: center;
	overflow: auto;
	width: 100%;
	white-space: nowrap;
	padding: 0 12px;
}

.menu-item {
	height: 40px;
	padding: 0 12px;
	transition-duration: 200ms;
	transition-property: opacity;

	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
	cursor: pointer;
}

.menu-item.active {
	background-color: var(--card-bg-color);
	opacity: 1;
}

.menu-title {
	font-size: 26px;
	font-weight: bold;
	padding-top: 27px;
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 10px;
}

.products {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding-left: 24px;
	padding-right: 24px;
	gap: 10px;
}

.product-card {
	flex: 1 1 calc(25% - 10px);
	max-width: calc(25% - 10px);
	box-sizing: border-box;
	height: 250px;
	margin-bottom: 10px;
	background-color: var(--card-bg-color);
	display: flex;
	padding: 6px;
	position: relative;
	border-radius: 16px;
	flex-direction: column;
	gap: 5px;
}

.product-image {
	max-height: 160px;
	text-align: center;
}

.product-image img {
	width: 100%;
	max-width: 100%;
	max-height: 100%;
	border-radius: 16px;
	height: 160px;
	object-fit: cover;
}

.product-price {
	font-size: 18px;
	font-weight: bold;
}

.product-name {
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.product-buttons {
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% - 10px);
}

.product-modal-image {
	width: 100%;
}

.product-modal-image img {
	width: 100%;
	max-height: 100vw;
	object-fit: cover;
	text-align: center;
}

.product-modal-buttons {
	display: flex;
	align-items: center;
	gap: 5px;
}

.product-modal-count {
	height: 56px;
	min-width: 120px;
	background-color: var(--card-bg-color);
	border-radius: 16px;
	white-space: nowrap;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 12px;
	font-weight: bold;
}

.product-modal-footer {
	display: none;
}

[data-rsbs-overlay] {
	max-height: 90vh;
	overflow-y: auto;
	background-color: var(--bg-color);
	border-top-left-radius: 24px;
	border-top-right-radius: 24px;
}

[data-rsbs-header]:before {
	background-color: var(--grey-color);
}

.bottom-padding.second {
	padding-bottom: 40px;
}

.product-modal-content {
	display: flex;
}

.product-modal-header {
	font-size: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 500;
	margin-bottom: 10px;
}

.product-description {
	font-size: 18px;
	white-space: break-spaces;
}

@media (min-width: 576px) {
	.product-modal-content {
		margin-bottom: 20px;
		gap: 10px;
	}

	.product-modal-image img {
		border-radius: 16px;
		max-height: 40vw;
	}
}

@media (max-width: 880px) {
	.product-card {
		/* width: calc(33.3% - 7px); */
		flex: 1 1 calc(33.3% - 7px);
		max-width: calc(33.3% - 7px);
	}
}

@media (max-width: 576px) {
	.container-lg.second {
		padding: 0px;
	}

	.product-card {
		width: calc(50% - 5px);
		flex: 1 1 calc(50% - 5px);
		max-width: calc(50% - 5px);
	}

	.product-modal-content {
		flex-direction: column;
	}

	.product-modal-footer {
		position: fixed;
		bottom: 0;
		display: block;

		font-size: 18px;
		width: 100%;
		padding: 8px;
		background-color: var(--bg-color);
		border-top: 1px solid rgba(0, 0, 0, .05);

		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.product-description {
		font-size: 16px;
		padding: 16px;
		padding-bottom: 45px;
	}

	.product-modal-header {
		display: none;
	}
}

/* From Uiverse.io by rishichawda */
/* The switch - the box around the slider */
#theme-toggle-button {
	font-size: 17px;
	position: relative;
	display: inline-block;
	width: 4em;
	cursor: pointer;
}

/* Hide default HTML checkbox */
#toggle {
	opacity: 0;
	width: 0;
	height: 0;
}

#container,
#patches,
#stars,
#button,
#sun,
#moon,
#cloud {
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 0.25s;
}

/* night sky background */
#toggle:checked+svg #container {
	fill: #2b4360;
}

/* move button to right when checked */
#toggle:checked+svg #button {
	transform: translate(28px, 2.333px);
}

/* show/hide sun and moon based on checkbox state */
#sun {
	opacity: 1;
}

#toggle:checked+svg #sun {
	opacity: 0;
}

#moon {
	opacity: 0;
}

#toggle:checked+svg #moon {
	opacity: 1;
}

/* show or hide background items on checkbox state */
#cloud {
	opacity: 1;
}

#toggle:checked+svg #cloud {
	opacity: 0;
}

#stars {
	opacity: 0;
}

#toggle:checked+svg #stars {
	opacity: 1;
}