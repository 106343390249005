body {
	font-family: "Outfit", sans-serif !important;
}


.feedback-button {
	background: #34b547;
	width: 100%;
	border: none;
	color: #fff;
	padding: 0 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 20px;
	font-weight: bold;
	height: 62px;
}

.rating {
	padding: 20px;
}

.question {
	font-size: 20px;
	font-weight: 500;
}

.input_types {
	padding-bottom: 30px;
	border-bottom: 1px solid #818181;
	margin: 20px 30px 24px;
}

.rateable {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.rateable-item {
	width: 50px;
	border: 1px solid #757575;
	padding: 3px 8px;
	border-radius: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 3px;
	cursor: pointer;
}

.radio-item,
.checkbox-item {
	display: flex;
	align-items: center;
	gap: 5px;
	margin-bottom: 10px;
}

.radio-item label,
.checkbox-item label {
	margin-bottom: 0;
	font-size: 16px;
	line-height: 16px;
	color: inherit;
}

.textarea-item {
	resize: none;
}

.selectable-items {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;
}

.selectable-item {
	border: 1px solid #757575;
	padding: 3px 12px;
	border-radius: 36px;
	cursor: pointer;
}

.rating-block:last-child .input_types {
	border: none;
}

.custom-radio {
	width: 16px;
	height: 16px;
	border: 1px solid #757575;
	border-radius: 50%;
}

.custom-checkbox {
	width: 16px;
	height: 16px;
	border: 1px solid #757575;
	border-radius: 4px;
}